<template>
  <b-card title="إضافة معلم جديد" class="col-12 col-lg-6 c">
    <div>
      <div ref="alert"></div>
      <div class="form-group">
        <label for=""><h5>الإسم</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="name"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>رقم الهوية</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="number"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>الجوال</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="phone"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>التخصص</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="ta5asos"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>الدرجة</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="degree"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>المستوى</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="level"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>رقم الوظيفة</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="job_number"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>العمل الحالي</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="current_job"
        />
      </div>
      <div class="form-group">
        <label for="">مجموعة الإعدادات</label>
        <select class="form-control" ref="settings_group">
          <option
            :value="group._id"
            v-for="group in groups"
            v-bind:key="group._id"
          >
            {{ group.title }}
          </option>
        </select>
      </div>
      <div class="col-12 text-center">
        <button
          class="btn btn-success"
          style="margin: 0px auto"
          @click="addTeacher()"
        >
          إضافة المعلم الآن
        </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  data(){
    return {
        user: JSON.parse(localStorage.getItem('user')),
        groups: [],
    }
  },
  created(){
      if(!checkPer("teachers|add")){
          this.$router.push('/NotPermitted')
          return false;
      }
      var _g = this;
      axios
      .post(api + "/user/teachers/groups", {
        jwt: _g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    addTeacher() {
        var g = this;
      var name = this.$refs.name.value,
        number = this.$refs.number.value,
        phone = this.$refs.phone.value,
        _g = this;
      if (
        name == "" ||
        name == undefined ||
        number == "" ||
        number == undefined ||
        phone == "" ||
        phone == undefined
      ) {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "هناك حقول مطلوبة",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      } else {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "جاري التحميل..",
            icon: "WarningIcon",
            variant: "warning",
          },
        });
        axios
          .post(api + "/user/teachers/add", {
            name: this.$refs.name.value,
            phone: this.$refs.phone.value,
            number: this.$refs.number.value,
            ta5asos: this.$refs.ta5asos.value,
            level: this.$refs.level.value,
            job_number: this.$refs.job_number.value,
            current_job: this.$refs.current_job.value,
            degree: this.$refs.degree.value,
            settings_group: this.$refs.settings_group.value,
            jwt: g.user.jwt,
          })
          .then(function (r) {
            _g.$toast.clear();
            if (r.data.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم اضافة المعلم بنجاح",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              _g.$router.push("/_teachers/list");
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.response,
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast.clear();
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>

<style>
</style>
